// Convert kebab-case to camelCase: my-page-asset > myPageAsset
export const camelize = s => s.replace(/-(.)/g, l => l[1].toUpperCase());

export const getReadableLabel = (value) => {
    // Perform any necessary mapping or transformations here
    // For example, you can convert the value to uppercase or apply other formatting rules
    // You can also check if a specific mapping exists in a separate object or API response
  
    // Placeholder implementation: Capitalize the first letter of each word
    return value
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };
  